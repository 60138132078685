/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/integratesearch/v4/integratesearch.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const V4CollectionType = {
    UNSPECIFIED: 'COLLECTION_TYPE_UNSPECIFIED',
    FLEAMARKET: 'COLLECTION_TYPE_FLEAMARKET',
    COMMUNITY: 'COLLECTION_TYPE_COMMUNITY',
    BUSINESS_POSTS: 'COLLECTION_TYPE_BUSINESS_POSTS',
    PLACE: 'COLLECTION_TYPE_PLACE',
    SHORTCUT: 'COLLECTION_TYPE_SHORTCUT',
    JOB: 'COLLECTION_TYPE_JOB',
    ADVERTISEMENT: 'COLLECTION_TYPE_ADVERTISEMENT',
    COMMERCE: 'COLLECTION_TYPE_COMMERCE',
    CAR: 'COLLECTION_TYPE_CAR',
    REALTY: 'COLLECTION_TYPE_REALTY',
    GROUP: 'COLLECTION_TYPE_GROUP',
    LOCAL_PROFILE: 'COLLECTION_TYPE_LOCAL_PROFILE',
    DSP_ADVERTISEMENT: 'COLLECTION_TYPE_DSP_ADVERTISEMENT'
} as const;

export type V4CollectionType = typeof V4CollectionType[keyof typeof V4CollectionType];



